/* ========= カラーコードマスタ ========= */
export const colorCodeMaster = {
  whiteCode: '#FFFFFF',
  whiteRGBA: 'rgba(203, 203, 203, 0.2)',
  inputBackgroundCode: 'F5F5F5',
  blackCode: '#000000',
  redCode: '#C1473A',
  greenCode: '#00B7CE',
  greenRGBA: 'rgba(0, 183, 206, 0.5)',
  placeholderCode: '#7F7F7F',
  btnBackgroundCode: '#545454',
  btnBackgroundHoverCode: '#4B6F7D',
};
