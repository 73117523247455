/*====== Define import ======*/
/*------ React ------*/
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { colorCodeMaster } from '../feature/theme';
import styled from 'styled-components';
import top_logo_top from '../05Images/top_logo_top.png';
import top_contact from '../05Images/top_contact.png';

/*====== Define styles ======*/
/*------ Styled Components ------*/

//全体
const Sdiv = styled.div`
  position: relative;
  min-height: 100vh;
  /* ########### 1100px以下 ########### */
  @media (max-width: 1099px) {
    display: flex;
    justify-content: center;
  }
  /* ########### 1920px以上 ########### */
  @media (min-width: 1921px) {
    display: flex;
    justify-content: center;
  }
`;
//画面幅指定
const SdivDisplay = styled.div`
  max-width: 1920px;
  /* ########### 1920px以上 ########### */
  @media (min-width: 1921px) {
    width: 90%;
  }
`;
//左右
const SdivSepa = styled.div`
  /* ########### 1100px以上 ########### */
  @media (min-width: 1100px) {
    display: flex;
  }
`;
//緑背景上部
const SdivBackTop = styled.div`
  position: absolute;
  width: 40%;
  height: 100%;
  z-index: -1;
  left: 0;
  background-color: ${colorCodeMaster.greenRGBA};
  clip-path: polygon(0% 0%, 100% 0%, 100% 0%, 68% 100%, 0% 100%, 0% 27%);
  /* ########### 1100px以下 ########### */
  @media (max-width: 1099px) {
    clip-path: polygon(0% 0%, 80% 0%, 0% 40%);
    width: 100%;
  }
  /* ########### 599px以下 ########### */
  @media (max-width: 599px) {
    clip-path: polygon(0% 0%, 65% 0%, 0% 32%);
    width: 100%;
  }
`;
//緑背景下部
const SdivBackUnder = styled.div`
  position: absolute;
  width: 46%;
  height: 100%;
  z-index: -1;
  left: 0;
  background-color: ${colorCodeMaster.greenRGBA};
  clip-path: polygon(0% 21%, 100% 100%, 0% 100%);
  /* ########### 1100px以下 ########### */
  @media (max-width: 1099px) {
    clip-path: polygon(0% 5%, 49% 31%, 0% 31%);
    width: 100%;
  }
  /* ########### 599px以下 ########### */
  @media (max-width: 599px) {
    clip-path: polygon(0% 5%, 49% 27.7%, 0% 27.7%);
    width: 100%;
  }
`;
//左部分
const SdivLeft = styled.div`
  width: 50%;
  margin-left: 96px;
  /* ########### 599px以下 ########### */
  @media (max-width: 599px) {
    width: 100%;
  }
  /* ########### 1100px以下 ########### */
  @media (max-width: 1099px) {
    margin: 0;
  }
`;
//右部分
const SdivRight = styled.div`
  width: 50%;
  margin-top: 10%;
  /* ########### 599px以下 ########### */
  @media (max-width: 599px) {
    width: 100vw;
  }
  /* ########### 600px以上～1100px以下 ########### */
  @media (min-width: 600px) and (max-width: 1099px) {
    width: 100%;
  }
  /* ########### 1100px以上 ########### */
  @media (min-width: 1100px) {
    display: flex;
    justify-content: end;
    align-items: center;
  }
`;
//タイトル
const SimgLogo = styled.img`
  position: relative;
  z-index: 10;
  width: clamp(25rem, -30.529rem + 63.46vw, 45.625rem);
  margin-top: 28%;
  /* ########### 1100px以下 ########### */
  @media (max-width: 1099px) {
    margin-top: 64px;
  }
  /* ########### 599px以下 ########### */
  @media (max-width: 599px) {
    width: clamp(15.625rem, 11.235rem + 23.41vw, 20rem);
    margin: 0 27px;
    margin-top: 64px;
  }
`;
//コンテナ
const SdivCon = styled.div`
  margin-right: 96px;
  /* ########### 1100px以下 ########### */
  @media (max-width: 1099px) {
    margin: 0;
  }
`;
//ABOUT全体
const SdivAbout = styled.div`
  background-color: ${colorCodeMaster.whiteRGBA};
  width: 100%;
  margin-bottom: 30px;
  /* ########### 599px以下 ########### */
  @media (max-width: 599px) {
    width: 100%;
  }
`;
//タイトルBOX
const SdivTitleBox = styled.div`
  border-bottom: 2px solid ${colorCodeMaster.whiteCode};
  margin: 0 30px;
  @media (max-width: 599px) {
    margin: 0 16px;
  }
`;
//項目タイトル
const SdivTitle = styled.div`
  font-size: 48px;
  padding-top: clamp(0.938rem, -1.955rem + 3.19vw, 1.875rem);
  color: ${colorCodeMaster.whiteCode};
  /* ########### 599px以下 ########### */
  @media (max-width: 599px) {
    font-size: 32px;
  }
`;
//項目サブタイトル
const SdivSubTitle = styled.div`
  font-size: 16px;
  font-weight: bold;
  margin-top: 5px;
  color: ${colorCodeMaster.whiteCode};
`;
//タイトル下の全体
const SdivFlex = styled.div`
  margin: 0 30px;
  color: ${colorCodeMaster.whiteCode};
  /* ########### 599px以下 ########### */
  @media (max-width: 599px) {
    margin: 0 16px;
  }
`;
//事業内容
const SdivContents = styled.div`
  font-size: 16px;
  line-height: 30px;
  padding: 20px 0;
`;
//会社概要
const SdivCompanyContents = styled.div`
  &:first-child {
    margin-top: 0;
  }
  margin-top: 30px;
  display: flex;
  height: 32px;
`;
//項目
const SdivItem = styled.div`
  width: 64px;
  background-color: ${colorCodeMaster.whiteCode};
  font-size: 16px;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
`;
//会社情報
const SdivName = styled.div`
  width: 100%;
  font-size: 16px;
  color: ${colorCodeMaster.whiteCode};
  margin-left: 30px;
  display: flex;
  align-items: center;
  /* ########### 599px以下 ########### */
  @media (max-width: 599px) {
    margin-left: 16px;
  }
`;
//下部分
const SdivUnder = styled.div`
  display: flex;
  position: absolute;
  bottom: 10px;
  /* ########### 1100px以下 ########### */
  @media (max-width: 1099px) {
    left: 50%;
    transform: translateX(-50%);
  }
`;
//コピーライト
const SdivCopy = styled.div`
  font-size: 12px;
  color: ${colorCodeMaster.whiteCode};
  font-family: 'Inter', sans-serif;
  position: relative;
  z-index: 10;
  margin-left: 96px;
  white-space: nowrap;
  /* ########### 1100px以下 ########### */
  @media (max-width: 1099px) {
    margin: 0;
  }
  /* ########### 599px以下 ########### */
  @media (max-width: 599px) {
    margin: 0;
    font-size: 10px;
  }
`;
//CONTACT右寄せ
const SdivEnd = styled.div`
  /* display: flex; */
  //height: auto;
  /* justify-content: end; */
  /* margin: 0 0 0 auto; */
`;
//CONTACTボタン
const SimgContact = styled.img`
  width: 100px;
  display: block;
  margin: 5% 0 5% auto;
  &:hover {
    filter: brightness(1.3);
  }
  /* ########### 1100px以下 ########### */
  @media (max-width: 1099px) {
  }
  /* ########### 599px以下 ########### */
  @media (max-width: 599px) {
    margin-right: 20px;
    margin-bottom: 30px;
    width: 60px;
  }
`;

/*====================*/
/*======  Main  ======*/
/*====================*/
export const Home = () => {
  /** ページタイトルを設定 */
  document.title = 'ANAHASHI ELECTRONICS';
  //項目名
  const companyArr = [
    { item: '会社名', name: 'アナハシエレクトロニクス合同会社', id: '1' },
    { item: '所在地', name: '愛知県名古屋市中区栄２丁目１５番１４号', id: '2' },
    { item: '設立', name: '2023年04月12日', id: '3' },
  ];

  // 画面遷移
  const navigate = useNavigate();
  //ストレージの情報をクリア
  sessionStorage.removeItem('FORM_INFO');

  //Contentsコンポーネント化
  const ContentsFlame = (props) => {
    const { title, subTitle, children } = props;

    return (
      <SdivAbout>
        <SdivTitleBox>
          <SdivTitle>{title}</SdivTitle>
          <SdivSubTitle>{subTitle}</SdivSubTitle>
        </SdivTitleBox>
        <SdivFlex>{children}</SdivFlex>
      </SdivAbout>
    );
  };

  /*======  表示  ======*/
  return (
    <Sdiv>
      <SdivBackTop />
      <SdivBackUnder />
      <SdivDisplay>
        <div>
          <SdivSepa>
            <SdivLeft>
              <SimgLogo src={top_logo_top} alt="ANAHASHIELECTRONICS" />
            </SdivLeft>
            <SdivRight>
              <SdivCon>
                {/* 会社説明 */}
                <div>
                  <ContentsFlame title="ABOUT" subTitle="アナハシとは">
                    <SdivContents>
                      エレクトロニクスを軸に、未来のサービスにむけた研究や開発に取り組んでいます。
                      <br />
                      ・スマートフォン向けアプリケーションの開発、運用
                      <br />
                      ・エレクトロニクス関連の市場調査
                    </SdivContents>
                  </ContentsFlame>
                  <ContentsFlame title="COMPANY" subTitle="会社情報">
                    <SdivContents>
                      <div>
                        {companyArr.map((item) => (
                          <SdivCompanyContents key={item.id}>
                            <SdivItem>{item.item}</SdivItem>
                            <SdivName>{item.name}</SdivName>
                          </SdivCompanyContents>
                        ))}
                      </div>
                    </SdivContents>
                  </ContentsFlame>
                  <SdivEnd>
                    <SimgContact src={top_contact} onClick={() => navigate('/contact')} />
                  </SdivEnd>
                </div>
              </SdivCon>
            </SdivRight>
          </SdivSepa>
          <SdivUnder>
            <SdivCopy>CopyRight © ANAHASHI ELECTRONICS Inc. ALL Rigths Reserved.</SdivCopy>
          </SdivUnder>
        </div>
      </SdivDisplay>
    </Sdiv>
  );
};
