/*====== Define import ======*/
/*------ React ------*/
import React from 'react';
/*------ CSS ------*/
import './App.css';
/*------ Route ------*/
import { BrowserRouter, Route, Routes } from 'react-router-dom';
/*------ main ------*/
import { NotFound } from './01Pages/NotFound';
import { Home } from './01Pages/Home';
import { Contact } from './01Pages/Contact';
import { Confirm } from './01Pages/Confirm';
import { Privacy } from './01Pages/Privacy';

export default function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          {/* ノットファウンド画面 */}
          <Route path="/" element={<NotFound />} />
          {/* トップ画面 */}
          {/* <Route path="/" element={<Home />} /> */}
          {/* お問い合わせ入力画面 */}
          <Route path="/contact1" element={<Contact />} />
          {/* お問い合わせ確認画面 */}
          <Route path="/confirm" element={<Confirm />} />
          {/* 個人情報保護方針画面 */}
          <Route path="/privacy" element={<Privacy />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}
