/*====== Define import ======*/
/*------ React ------*/
import React from 'react';
import styled from 'styled-components';

/*====== Define styles ======*/
/*------ Styled Components ------*/

//全体
const Sdiv = styled.div`
  font-size: 48px;
  pointer-events: none;
`;

/*====================*/
/*======  Main  ======*/
/*====================*/
export const NotFound = () => {
  /*======  表示  ======*/
  return <Sdiv>404 Not Found</Sdiv>;
};
