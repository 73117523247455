/*====== Define import ======*/
/*------ React ------*/
import React from 'react';
import styled from 'styled-components';
import { colorCodeMaster } from '../feature/theme';

/*====== Define styles ======*/
/*------ Styled Components ------*/

//ボタンの枠
const SbuttonFrame = styled.button`
  width: 240px;
  height: 56px;
  padding: 1px;
  background-color: ${colorCodeMaster.whiteCode};
  clip-path: polygon(6.25% 0%, 100% 0%, 100% 73%, 93.75% 100%, 0% 100%, 0% 27%);
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  box-sizing: border-box;
  /* ########### 599px以下 ########### */
  @media (max-width: 599px) {
    display: none;
  }
`;
//ボタン
const SdivButton = styled.div`
  width: 100%;
  height: 54px;
  background-color: ${colorCodeMaster.btnBackgroundCode};
  color: ${colorCodeMaster.whiteCode};
  font-size: 16px;
  clip-path: polygon(6% 0%, 100% 0%, 100% 72%, 94% 100%, 0% 100%, 0% 27%);
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'RyakujichouMinchoStencil';
  white-space: nowrap;
  &:hover {
    background-color: ${colorCodeMaster.btnBackgroundHoverCode};
  }
  &:active {
    top: 1px;
    left: 1px;
    box-shadow: none;
    filter: brightness(1.3);
  }
  /* ########### 599px以下 ########### */
  @media (max-width: 599px) {
    display: none;
  }
`;
const SbuttonFrameMobile = styled(SbuttonFrame)`
  display: none;
  width: 128px;
  /* ########### 599px以下 ########### */
  @media (max-width: 599px) {
    display: flex;
  }
`;
const SdivButtonMobile = styled(SdivButton)`
  display: none;

  /* ########### 599px以下 ########### */
  @media (max-width: 599px) {
    display: flex;
  }
`;

/*====================*/
/*======  Main  ======*/
/*====================*/
export const Button = (props) => {
  const { onClick, disabled, text, mobileText } = props;
  /*======  表示  ======*/
  return (
    <>
      <SbuttonFrame onClick={onClick} disabled={disabled}>
        <SdivButton>{text}</SdivButton>
      </SbuttonFrame>
      <SbuttonFrameMobile onClick={onClick} disabled={disabled}>
        <SdivButtonMobile>{mobileText}</SdivButtonMobile>
      </SbuttonFrameMobile>
    </>
  );
};
