/*====== Define import ======*/
/*------ React ------*/
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { colorCodeMaster } from '../feature/theme';
import common_logo_center from '../05Images/common_logo_center.png';
import { Button } from '../02Components/Button';

/*====== Define styles ======*/
/*------ Styled Components ------*/

const StitleWrapper = styled.div`
  text-align: center;
  margin: 64px 0 32px 0;
  /* ########### 599px以下 ########### */
  @media (max-width: 599px) {
    margin: 32px 0 16px 0;
  }
`;
const Simg = styled.img`
  width: 228.04px;
  height: 58px;
  margin: 0 0 32px 0;
  /* ########### 599px以下 ########### */
  @media (max-width: 599px) {
    margin: 0 0 16px 0;
  }
`;
const SPageTitle = styled.h1`
  font-size: 32px;
  color: ${colorCodeMaster.whiteCode};
  margin: 0;
  /* ########### 599px以下 ########### */
  @media (max-width: 599px) {
    font-size: 16px;
  }
`;
const SgrassWrapper = styled.div`
  background-color: ${colorCodeMaster.whiteRGBA};
  color: ${colorCodeMaster.whiteCode};
  max-width: 1152px;
  width: 90%;
  margin: 0 auto;
  font-size: 16px;
  height: calc(100vh - 312px);
  overflow-y: scroll;
  /* ########### 600px以上～768px以下 ########### */
  @media (min-width: 600px) and (max-width: 768px) {
    width: 100%;
  }
  /* ########### 599px以下 ########### */
  @media (max-width: 599px) {
    width: 100%;
    height: calc(100vh - 260px);
  }
`;
const SgrassInner = styled.div`
  margin: 0 64px;
  padding: 32px 0;
  /* ########### 600px以上～768px以下 ########### */
  @media (min-width: 600px) and (max-width: 768px) {
    margin: 0 32px;
  }
  /* ########### 599px以下 ########### */
  @media (max-width: 599px) {
    margin: 0 16px;
  }
`;
//フォームの入力
const SdivInput = styled.div`
  max-width: 608px;
  width: 100%;
  margin: 0 auto;
`;
//名前と必須部分
const SdivNameFlex = styled.div`
  display: flex;
`;
//名前
const SdivFormName = styled.div`
  font-size: 16px;
  margin-bottom: 8px;
  white-space: nowrap;
`;
//必須
const SdivMust = styled.div`
  font-size: 13px;
  margin-top: 3px;
  margin-left: 7px;
  white-space: nowrap;
`;
//入力欄
const Sinput = styled.input`
  width: 100%;
  height: 56px;
  margin-bottom: 16px;
  box-sizing: border-box;
  border-radius: 8px;
  font-size: 16px;
  font-family: 'Inter';
  padding: 16px;
  &:focus {
    outline: solid 2px ${colorCodeMaster.greenCode};
  }
  &::placeholder {
    color: ${colorCodeMaster.placeholderCode};
  }
`;
//テキストエリア
const Stextarea = styled.textarea`
  width: 100%;
  height: 168px;
  box-sizing: border-box;
  border-radius: 8px;
  font-family: 'Inter';
  font-size: 16px;
  padding: 16px;
  resize: vertical;
  &:focus {
    outline: solid 2px ${colorCodeMaster.greenCode};
  }
`;
//下のボックス
const SdivAction = styled.div``;
//個人情報保護方針
const SdivPrivacy = styled.div`
  justify-content: center;
  display: flex;
  margin-bottom: 32px;
  font-size: 16px;
  font-family: 'Inter';
  @media (max-width: 430px) {
    font-size: 14px;
  }
`;
//個人情報保護方針のスタイル
const SspanA = styled.a`
  text-decoration: underline;
  color: ${colorCodeMaster.whiteCode};
  cursor: pointer;
  &:hover {
    filter: brightness(0.8);
  }
`;
//チェクボックス
const SinputCheck = styled.input`
  width: 17px;
  height: 17px;
  margin-right: 20px;
  cursor: pointer;
  accent-color: ${colorCodeMaster.greenCode};
`;
//ボタンの全体
const SdivButtonBox = styled.div`
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  max-width: 608px;
`;
//チェックマーク
const SdivIcon = styled.div`
  font-size: 14px;
  font-family: 'Inter';
  font-weight: 600;
  color: ${colorCodeMaster.redCode};
  margin: 0 0 0 16px;
`;
const Scopyright = styled.div`
  color: ${colorCodeMaster.whiteCode};
  font-size: 12px;
  font-family: 'Inter';
  position: fixed;
  bottom: 8px;
  left: 50%;
  white-space: nowrap;
  transform: translate(-50%, -50%);
`;
const RecapchaTxt = styled.div`
  font-size: 8px;
  color: ${colorCodeMaster.whiteCode};
  width: 100%;
  margin: 4px 0 32px 0;
`;
const Sa = styled.a`
  font-size: 8px;
  color: ${colorCodeMaster.whiteCode};
  cursor: pointer;
  text-decoration: underline;
  &:hover {
    filter: brightness(0.8);
  }
`;

/*====================*/
/*======  Main  ======*/
/*====================*/
export const Contact = () => {
  /** ページタイトルを設定 */
  document.title = 'ANAHASHI ELECTRONICS | お問い合わせフォーム';
  // 画面遷移
  const navigate = useNavigate();
  //コンタクトステート
  let formInfo = JSON.parse(sessionStorage.getItem('FORM_INFO'));
  let getInfo =
    formInfo === null
      ? {
          name: '',
          affiliation: '',
          number: '',
          mail: '',
          inquiry: '',
        }
      : {
          name: formInfo.name,
          affiliation: formInfo.affiliation,
          number: formInfo.number,
          mail: formInfo.mail,
          inquiry: formInfo.inquiry,
        };

  const [information, setInformation] = useState(getInfo); //コンタクトステート
  const [checked, setChecked] = useState(false); //チェックボックスステート
  const [validateList, setValidateList] = useState({
    name: undefined,
    affiliation: undefined,
    number: undefined,
    mail: undefined,
    inquiry: undefined,
  });

  //CONTACTの配列
  const contactArr = [
    { name: '氏名', information: 'name', type: 'input', required: true, placeholder: '田中 太郎' },
    {
      name: '会社名 / 学校名',
      information: 'affiliation',
      type: 'input',
      required: false,
      placeholder: '××会社 / ××学校',
    },
    {
      name: '電話番号(ハイフンなし)',
      information: 'number',
      type: 'input',
      required: false,
      placeholder: '00011112222',
    },
    { name: 'メールアドレス', information: 'mail', type: 'input', required: true, placeholder: 'example@example.com' },
    { name: 'お問い合わせ内容', information: 'inquiry', type: 'textarea', required: true },
  ];

  //文字が変わるとコンタクトステートが更新される
  const onInputChange = (e, key) => {
    setInformation({ ...information, [key]: e.target.value });

    //電話番号のバリデーション
    if (key === 'number') {
      const re = /^(?!\s)(?!.*\s$)(?!.*\s{2,})[0-9\s]*$/;
      setValidateList({
        ...validateList,
        number: re.test(e.target.value) ? undefined : 'number',
      });
    }
    //メールのバリデーション
    if (key === 'mail') {
      const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      setValidateList({
        ...validateList,
        mail: re.test(e.target.value) ? undefined : 'mail',
      });
    }
  };

  //チェックボックスの状態を切り替える
  const onPrivacyChange = () => {
    setChecked(!checked);
  };

  //入力内容確認ボタンを押すと遷移
  const onSend = () => {
    //必須バリデーション
    let required = RegExp(/^(?!\s*$).+/); //必須
    // フォーマットバリデーション
    const number = RegExp(/^(?!\s)(?!.*\s$)(?!.*\s{2,})[0-9\s]*$/);
    const mail = RegExp(/^[\w\-._]+@[\w\-._]+\.[A-Za-z]+/);
    let validateRaw = {
      ...validateList,
      name: required.test(information.name) ? undefined : 'require',
      number: number.test(information.number) ? undefined : 'number',
      mail: required.test(information.mail) ? (mail.test(information.mail) ? undefined : 'mail') : 'require',
      inquiry: required.test(information.inquiry) ? undefined : 'require',
    }; // コピー用
    setValidateList(validateRaw);
    if (Object.values(validateRaw).filter((i) => i === undefined).length !== 5 || !checked) {
      return;
    } else {
      sessionStorage.setItem('FORM_INFO', JSON.stringify(information));
      navigate('/confirm');
    }
  };

  const validationReturn = (v) => {
    switch (v) {
      case 'require':
        return '入力してください。';
      case 'number':
        return '半角数字のみで入力してください。';
      case 'mail':
        return 'フォーマットが間違っています。';
      default:
        return '';
    }
  };

  /*======  表示  ======*/
  return (
    <>
      <StitleWrapper>
        <Simg src={common_logo_center} alt="ANAHASHI ELECTRONICS LOGO" />
        <SPageTitle>お問い合わせフォーム</SPageTitle>
      </StitleWrapper>

      <SgrassWrapper>
        <SgrassInner>
          {contactArr.map((item) => (
            <SdivInput key={item.name}>
              <SdivNameFlex>
                <SdivFormName>{item.name}</SdivFormName>
                {item.required && <SdivMust>(必須)</SdivMust>}
                <SdivIcon>{validationReturn(validateList[item.information])}</SdivIcon>
              </SdivNameFlex>
              {item.type === 'input' ? (
                <>
                  <Sinput
                    type="text"
                    placeholder={item.placeholder}
                    value={information[item.information]}
                    onChange={(e) => onInputChange(e, item.information)}
                    maxLength={
                      item.information === 'name' || item.information === 'affiliation'
                        ? 30
                        : item.information === 'number'
                          ? 11
                          : item.information === 'mail'
                            ? 256
                            : undefined
                    }
                  />
                </>
              ) : (
                <>
                  <Stextarea
                    value={information[item.information]}
                    onChange={(e) => onInputChange(e, item.information)}
                    maxLength={item.information === 'inquiry' ? 1000 : undefined}
                  />
                  <RecapchaTxt>
                    This site is protected by reCAPTCHA and the Google
                    <Sa href="https://policies.google.com/privacy" target="blank">
                      Privacy Policy
                    </Sa>{' '}
                    and
                    <Sa href="https://policies.google.com/terms" target="blank">
                      Terms of Service
                    </Sa>{' '}
                    apply.
                  </RecapchaTxt>
                </>
              )}
            </SdivInput>
          ))}
          <SdivAction>
            <SdivPrivacy>
              <SinputCheck type="checkbox" checked={checked} onChange={onPrivacyChange} />
              <SspanA href="http://localhost:3000/privacy" target="_blank">
                個人情報保護方針
              </SspanA>
              に同意する
            </SdivPrivacy>
            <SdivButtonBox>
              <Button text="＜　TOPに戻る" mobileText="＜　戻る" onClick={() => navigate('/')} />
              <Button text="入力内容を確認する　＞" mobileText="確認　＞" onClick={onSend} />
            </SdivButtonBox>
          </SdivAction>
        </SgrassInner>
      </SgrassWrapper>

      <Scopyright>CopyRight © ANAHASHI ELECTRONICS Inc. ALL Rigths Reserved.</Scopyright>
    </>
  );
};
