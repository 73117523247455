/*====== Define import ======*/
/*------ React ------*/
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';
import { colorCodeMaster } from '../feature/theme';
import common_logo_center from '../05Images/common_logo_center.png';
import { Button } from '../02Components/Button';
import axios from 'axios';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

/*====== Define styles ======*/
/*------ Styled Components ------*/

const StitleWrapper = styled.div`
  text-align: center;
  margin: 64px 0 32px 0;
  /* ########### 599px以下 ########### */
  @media (max-width: 599px) {
    margin: 32px 0 16px 0;
  }
`;
const Simg = styled.img`
  width: 228.04px;
  height: 58px;
  margin: 0 0 32px 0;
  /* ########### 599px以下 ########### */
  @media (max-width: 599px) {
    margin: 0 0 16px 0;
  }
`;
const SPageTitle = styled.h1`
  font-size: 32px;
  color: ${colorCodeMaster.whiteCode};
  margin: 0;
  /* ########### 599px以下 ########### */
  @media (max-width: 599px) {
    font-size: 16px;
  }
`;
const SgrassWrapper = styled.div`
  background-color: ${colorCodeMaster.whiteRGBA};
  color: ${colorCodeMaster.whiteCode};
  max-width: 1152px;
  width: 90%;
  margin: 0 auto;
  font-size: 16px;
  height: calc(100vh - 312px);
  overflow-y: scroll;
  /* ########### 600px以上～768px以下 ########### */
  @media (min-width: 600px) and (max-width: 768px) {
    width: 100%;
  }
  /* ########### 599px以下 ########### */
  @media (max-width: 599px) {
    width: 100%;
    height: calc(100vh - 260px);
  }
`;
const SgrassInner = styled.div`
  margin: 0 64px;
  padding: 32px 0;
  /* ########### 600px以上～768px以下 ########### */
  @media (min-width: 600px) and (max-width: 768px) {
    margin: 0 32px;
  }
  /* ########### 599px以下 ########### */
  @media (max-width: 599px) {
    margin: 0 16px;
  }
`;
//フォームの入力
const SdivInput = styled.div`
  max-width: 608px;
  width: 100%;
  margin: 0 auto;
`;
//項目タイトル
const SitemTitle = styled.div`
  font-size: 16px;
  border-bottom: 1px solid ${colorCodeMaster.whiteCode};
`;
//項目コンテンツ
const SitemContent = styled.div`
  font-family: 'Inter';
  font-size: 18px;
  padding: 16px;
  margin: 0 0 16px 0;
  word-break: break-all;
  overflow-wrap: break-word;
`;
//下のボックス
const SdivAction = styled.div`
  margin: 64px 0 0 0;
`;
//ボタンの全体
const SdivButtonBox = styled.div`
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  max-width: 608px;
`;
const Scopyright = styled.div`
  color: ${colorCodeMaster.whiteCode};
  font-size: 12px;
  font-family: 'Inter';
  position: fixed;
  bottom: 8px;
  left: 50%;
  white-space: nowrap;
  transform: translate(-50%, -50%);
`;
/** メッセージのスタイル **/
const Soverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
`;
const SmessegeWrapper = styled.div`
  position: fixed;
  z-index: 20;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  width: 544px;
  padding: 32px 32px 24px 32px;
  background-color: ${(props) => (props.isError ? colorCodeMaster.redCode : colorCodeMaster.greenCode)};
  clip-path: polygon(6.25% 0%, 100% 0%, 100% 73%, 93.75% 100%, 0% 100%, 0% 27%);
  border: none;
  box-sizing: border-box;
  /* ########### 599px以下 ########### */
  @media (max-width: 599px) {
    width: 90%;
  }
`;
const Stitle = styled.div`
  color: ${colorCodeMaster.whiteCode};
  font-size: 36px;
  border-bottom: 2px solid ${colorCodeMaster.whiteCode};
`;
const Smesseage = styled.div`
  margin: 8px 0 0 0;
  color: ${colorCodeMaster.whiteCode};
  font-size: 16px;
  font-family: 'Inter';
`;
const SstatusCode = styled.div`
  color: ${colorCodeMaster.whiteCode};
  font-size: 12px;
`;
/** ローディングのスタイル **/
// アニメーションのキー フレームを定義
const squareMove2AnimationBefore = keyframes`
  0% {
    transform: translate(0%, 0%);
  }

  25% {
    transform: translate(100%, 0%);
  }

  50% {
    transform: translate(100%, 100%);
  }

  75% {
    transform: translate(0%, 100%);
  }

  100% {
    transform: translate(0%, 0%);
  }
`;
const squareMove2AnimationAfter = keyframes`
  0% {
    transform: translate(0%, 0%);
  }

  25% {
    transform: translate(-100%, 0%);
  }

  50% {
    transform: translate(-100%, -100%);
  }

  75% {
    transform: translate(0%, -100%);
  }

  100% {
    transform: translate(0%, 0%);
  }
`;
const Sloading = styled.div`
  position: fixed;
  z-index: 20;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  --size: 32px;
  --color: ${colorCodeMaster.whiteCode};
  --animation-timing-function: linear;
  --animation-duration: 2s;
  width: var(--size);
  height: var(--size);
  &::before,
  &::after {
    content: '';
    position: absolute;
    width: 50%;
    height: 50%;
    background-color: var(--color);
  }
  &::before {
    top: 0;
    left: 0;
    transform: translate(0%, 0%);
    animation: ${squareMove2AnimationBefore} var(--animation-duration) var(--animation-timing-function) infinite;
  }
  &::after {
    right: 0;
    bottom: 0;
    transform: translate(0%, 0%);
    animation: ${squareMove2AnimationAfter} var(--animation-duration) var(--animation-timing-function) infinite;
  }
`;

/*====================*/
/*======  Main  ======*/
/*====================*/
export const Confirm = () => {
  /** ページタイトルを設定 */
  document.title = 'ANAHASHI ELECTRONICS | お問い合わせフォーム';

  const { executeRecaptcha } = useGoogleReCaptcha(); // reCapcha execute

  //CONTACTの配列
  const contactArr = [
    { name: '氏名', information: 'name' },
    { name: '会社名 / 学校名', information: 'affiliation' },
    { name: '電話番号(ハイフンなし)', information: 'number' },
    { name: 'メールアドレス', information: 'mail' },
    { name: 'お問い合わせ内容', information: 'inquiry' },
  ];

  // 画面遷移
  const navigate = useNavigate();

  const [apiResponse, setApiResponse] = useState({ statusCode: undefined, errorCode: undefined }); //APIメッセージ格納用
  const [isMessage, setIsMessage] = useState(false); //メッセージ表示用
  const [isLoading, setIsLoading] = useState(false); //ローディング表示用
  const information = JSON.parse(sessionStorage.getItem('FORM_INFO')) || {}; // デフォルト値として空のオブジェクトを設定

  const handleBack = () => {
    navigate('/contact');
  };

  //修正必要かも
  const handleSend = async () => {
    setIsLoading(true);
    // reCapcha execute
    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available');
      return;
    }
    // get reCapcha token
    const reCaptchaToken = await executeRecaptcha('confirm');
    // reCapcha authrization
    axios
      .post(
        `${process.env.REACT_APP_PHP_SEND_MAIL_ENDPOINT}/recaptcha.php`,
        JSON.stringify({ recaptcha_token: reCaptchaToken }),
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        },
      )
      .then((res) => {
        if (res.data.status === '200') {
          //成功処理
          axios
            .post(`${process.env.REACT_APP_PHP_SEND_MAIL_ENDPOINT}/send_email.php`, JSON.stringify(information), {
              headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
              },
            })
            .then((res) => {
              if (res.data.status === '200') {
                //成功処理
                setIsLoading(false);
                setIsMessage(true);
                //ストレージの情報をクリア
                sessionStorage.removeItem('FORM_INFO');
                //1.5秒後にTOP画面に遷移
                setTimeout(() => {
                  navigate('/', { replace: true });
                }, 1500);
              } else if (res.data.status === '401' || res.data.status === '404') {
                //失敗処理
                setApiResponse({ statusCode: res.data.status, errorCode: 400000 });
                setIsLoading(false);
                setIsMessage(true);
              }
            })
            .catch((error) => {
              //API error
              console.error('Request failed:', error);
              setApiResponse({ statusCode: 500, errorCode: 500000 });
              setIsLoading(false);
              setIsMessage(true);
            });
        } else if (res.data.status === '401' || res.data.status === '404') {
          setApiResponse({ statusCode: res.data.status, errorCode: 400000 });
          setIsLoading(false);
          setIsMessage(true);
        }
      })
      // API error
      .catch((error) => {
        console.error('Request failed:', error);
        setApiResponse({ statusCode: 500, errorCode: 500000 });
        setIsLoading(false);
        setIsMessage(true);
      });
  };

  const MessegeParts = (props) => {
    const { statusCode, errorCode } = props;
    let isError = statusCode !== undefined && errorCode !== undefined;
    return (
      <>
        <Soverlay onClick={() => (isError ? setIsMessage(false) : '')} />
        <SmessegeWrapper isError={isError}>
          <Stitle>{isError ? 'ERROR!' : 'SUCCESS!'}</Stitle>
          <Smesseage>
            {isError
              ? 'メール送信できませんでした。再度送信してください。'
              : 'メール送信が完了しました。TOP画面に戻ります。'}
          </Smesseage>
          {isError && (
            <SstatusCode>
              StatusCode:{statusCode} ErrorCode:{errorCode}
            </SstatusCode>
          )}
        </SmessegeWrapper>
      </>
    );
  };

  const Loading = () => {
    return (
      <>
        <Soverlay />
        <Sloading />
      </>
    );
  };

  /*======  表示  ======*/
  return (
    <>
      <StitleWrapper>
        <Simg src={common_logo_center} alt="ANAHASHI ELECTRONICS LOGO" />
        <SPageTitle>お問い合わせフォーム</SPageTitle>
      </StitleWrapper>

      <SgrassWrapper>
        <SgrassInner>
          {information &&
            contactArr.map((item) => (
              <SdivInput key={item.name}>
                <SitemTitle>{item.name}</SitemTitle>
                <SitemContent>{information[item.information]}</SitemContent>
              </SdivInput>
            ))}
          <SdivAction>
            <SdivButtonBox>
              <Button text="＜　入力画面に戻る" mobileText="＜　戻る" onClick={handleBack} />
              <Button text="入力内容を送信する　＞" mobileText="送信　＞" onClick={handleSend} />
            </SdivButtonBox>
          </SdivAction>
        </SgrassInner>
      </SgrassWrapper>

      {/* メッセージパーツ */}
      {isMessage && <MessegeParts statusCode={apiResponse.statusCode} errorCode={apiResponse.errorCode} />}

      {isLoading && <Loading />}

      <Scopyright>CopyRight © ANAHASHI ELECTRONICS Inc. ALL Rigths Reserved.</Scopyright>
    </>
  );
};
